

import TabMenu from "@mmsb/primevue-forked/tabmenu";

export default {
  components: { TabMenu },
  setup(){
    const items = [
      {label: "All genomes",
      to : "/all-genomes"}, 
      {label: "Specific gene",
      to : "/specific-gene"}
    ]

    return {items}
  }
  
}
