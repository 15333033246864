<template>
  <p class="text-center py-3">Welcome on the CSTB website !</p>
  <p class="text-center">
    Choose a tab above between "All Genomes" and "Specific Gene".
  </p>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>