
import { defineComponent, ref, Ref } from "vue";
import TwoTrees from "../components/TwoTrees.vue";
import FileUpload from "@mmsb/primevue-forked/fileupload";
import Button from '@mmsb/primevue-forked/button'; 
import { useRouter } from "vue-router";

export default defineComponent({
  components: { TwoTrees, FileUpload, Button },
  setup() {
    const checked = ref(false);
    const seqFromFile = ref();

    const onUpload = (event: any) => {
      if (event.files) {
        const file = event.files[0];
        const reader: FileReader = new FileReader();
        reader.onload = function () {
          const text = reader.result;
          seqFromFile.value = text;
        };
        reader.readAsText(file);
      } else {
        alert("Pas de fichier selectionné");
      }
    };

    const onSelect = () => {
      var chooseButton: HTMLElement | null = document.querySelector(
        "span.p-fileupload-choose"
      );
      if (chooseButton) {
        chooseButton.setAttribute("style", "fill-opacity: .25;");
      }
    };

    const onRemove = (event: any) => {
      seqFromFile.value = "";
      var button = document.querySelector("span.p-fileupload-choose input");
      if (button) {
        button.removeAttribute("disabled");
        // event.files[0]
      }
    };

    const seq: Ref<string | undefined> = ref();

    // const format = (fasta: string) => {
    //   // display of fasta query in blocks header + 70nt
    //   var newFasta = "";
    //   if (fasta[0] === ">") {
    //     var header = "";
    //     var seq = "";
    //     var head = true;
    //     for (let i = 0; i < fasta.length; i++) {
    //       if (head) {
    //         header += fasta[i];
    //       } else {
    //         if (i > 0 && i % 70 === 0) {
    //           seq += " " + fasta[i];
    //         } else {
    //           seq += fasta[i];
    //         }
    //       }
    //       if (fasta[i] === "\n") {
    //         head = false;
    //       }
    //     }
    //     newFasta = header + "\n" + seq;
    //   }
    //   return newFasta;
    // };

    const verifyFasta = (sequence: string) => {
      const fasta = /(^>[^]+\n[acgtACGT\s]+$)|(^[acgtACGT\s]+$)/;
      let nbSeq = 0;
      const splitSeq = sequence.split("\n");

      if (sequence !== "") {
        // empty sequence
        if (fasta.test(sequence)) {
          // sequence in fasta
          for (let i = 0; i < splitSeq.length; i++) {
            // test number of sequences
            if (splitSeq[i][0] == ">") {
              nbSeq += 1;
              if (nbSeq > 1) {
                alert("More than one sequence.");
                return;
              }
            }
          }
          // sequence = format(sequence);
          checked.value = true;
        } else {
          alert("Sequence not in fasta format");
        }
      } else {
        alert("Empty sequence.");
      }
    };

    const next = () => {
      seq.value = (
        document.getElementById("sequence") as HTMLInputElement
      ).value;
      verifyFasta(seq.value);
    };

    const router = useRouter();

    const resultsPage = () => {
      router.replace("/results");
    };

    return {
      onUpload,
      next,
      checked,
      seq,
      seqFromFile,
      onRemove,
      onSelect,
      resultsPage,
    };
  },
});
