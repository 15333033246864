
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import TwoTrees from "../components/TwoTrees.vue";

export default defineComponent({
  components: { TwoTrees },
  setup() {
    const router = useRouter();
    const resultsPage = () => {
      router.replace("/results");
    };

    return { resultsPage };
  },
});
