<template>
  <div
    class="w-full bg-gray-100 py-1"
  >
    <p class="text-center text-sm">Contact : cstb-support@ibcp.fr</p>
    <p class="text-center text-sm">
      UMR 5086 MMSB -
      <a
        href="https://mmsb.cnrs.fr/equipe/transfert-d-adn-entre-cellules-bacteriennes/"
        class="underline"
        >Lesterlin LAB</a
      >
    </p>
  </div>
</template>